<template>
  <div>
    <div class="row justify-content-center justify-content-around">
      <!-- <div class="demo-inline-spacing">
          <b-button variant="outline-primary" @click="showAlert">
            Basic
          </b-button>
        </div> -->
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ตั้งค่าตำแหน่ง</p>
            <hr />
          </div>
          <!-- search input -->
          <div class="custom-search row justify-content-between">
            <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1 class="btn" variant="success" @click="add_user"> เพิ่มตำแหน่ง </b-button>
            </div>
            <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>

                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      <span>Excel</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <b-overlay :show="show" rounded="sm">
            <vue-good-table
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
              responsive
              :line-numbers="true"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                  <b-avatar :src="props.row.avatar" class="mx-1" />
                  <span class="text-nowrap">{{ props.row.fullName }}</span>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'ดูรายการ'">
                  <span>
                    <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                      <template v-slot:button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                      </template>
                      <b-dropdown-item v-b-modal="`modal-2${props.index}`" @click="edit_user(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="delete_user(props.row)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>

                  <b-modal
                    :id="`modal-2${props.index}`"
                    content-class="shadow"
                    title="แก้ไขโปรไฟล์"
                    ok-title="ตกลง"
                    cancel-title="ยกเลิก"
                    size="lg"
                    @ok="up_user(props)"
                    no-close-on-backdrop
                  >
                    <b-card-text>
                      <!-- {{ props.row }} -->
                      <b-form>
                        <div>
                          <!-- <div class="text-center">
                              <span
                                class="b-avatar badge-light rounded-circle mb-1"
                                style="width: 114px; height: 114px"
                                ><span class="b-avatar-img"
                                  ><img
                                    :src="`${API}files/image/${props.row.image[0]}`"
                                    alt="Image" /></span
                              ></span>
                            </div> -->

                          <!-- <div class="mb-1 mt-1">
                              <b-form-file
                                placeholder="Choose a file or drop it here..."
                                drop-placeholder="Drop file here..."
                                @change="onFileChange"
                              />
                            </div> -->
                          <b-row>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="Username:">
                              <template v-slot:label> Username <span class="text-danger"> *</span> </template>
                              <b-form-input id="quantity" v-model="props.row.username" placeholder="Username" />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="Password:">
                              <template v-slot:label> Password <span class="text-danger"> *</span> </template>
                              <b-form-input
                                id="quantity"
                                v-model="props.row.password"
                                type="password"
                                placeholder="Password"
                              />
                            </b-form-group>
                          </b-row>
                          <div class="row">
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4">
                              <template v-slot:label> ชื่อ<span class="text-danger"> *</span> </template>
                              <b-form-input id="detall" v-model="props.row.firstname" placeholder="ชื่อ" />
                            </b-form-group>
                            <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4">
                              <template v-slot:label> นามสกุล <span class="text-danger"> *</span> </template>
                              <b-form-input id="detall" v-model="props.row.lastname" placeholder="นามสกุล" />
                            </b-form-group>
                          </div>

                          <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
                            <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
                            <v-select
                              placeholder="เลือกกลุ่มงาน"
                              v-model="props.row.work_gid"
                              label="work_gname"
                              :options="workGs"
                              @input="work_select($event)"
                            />
                          </b-form-group>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ฝ่ายงาน:">
                            <template v-slot:label> ฝ่ายงาน <span class="text-danger"> *</span> </template>
                            <v-select
                              placeholder="เลือกฝ่ายงาน"
                              v-model="props.row.dep_id"
                              label="dep_name"
                              :options="departments"
                            />
                          </b-form-group>
                          <div>
                            <b-form-group label-cols="3" label-cols-lg="2" label="หัวหน้ากลุ่มงาน:">
                              <template v-slot:label> หัวหน้ากลุ่มงาน <span class="text-danger"> </span> </template>
                              <b-form-checkbox
                                v-model="group_head_value"
                                name="check-button"
                                checked="true"
                                switch
                                inline
                                @change="group_head(group_head_value)"
                              />
                            </b-form-group>
                          </div>
                          <b-form-group label-cols="3" label-cols-lg="2" label="ฝ่ายงาน:">
                            <template v-slot:label> Status <span class="text-danger"> *</span> </template>

                            <validation-provider #default="{ errors }" rules="required">
                              <v-select
                                :state="errors.length > 0 ? false : null"
                                placeholder="สถานะ"
                                v-model="props.row.usertype"
                                label="title"
                                :options="statuss"
                              />
                            </validation-provider>
                          </b-form-group>
                        </div>
                      </b-form>
                    </b-card-text>
                  </b-modal>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Showing 1 to </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['5', '10', '15', '25', '50', '100']"
                      class="mx-1"
                      @input="(value) => props.perPageChanged({ currentPerPage: value }, handleChangePage(value))"
                    />
                    <span class="text-nowrap">of {{ total }} entries</span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="handlePagechange($event)"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-overlay>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div>
      <b-modal
        id="modal-1"
        cancel-variant="outline-secondary"
        ok-variant="gradient-success"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        title="เพิ่มตำแหน่ง"
        size="lg"
        no-close-on-backdrop
        @ok="showAlert"
      >
        <b-form>
          <div>
            <label for="name">รหัส สถานที่:</label>
            <b-input type="text" id="name" v-model="formData.name" />

            <label for="email">ชื่อสถานที่:</label>
            <b-input type="text" id="email" v-model="formData.email" />
            <div class="row">
              <div class="col-6">
                <label for="email">GPS lat:</label>
                <b-input type="text" id="email" v-model="formData.email" />
              </div>
              <div class="col-6">
                <label for="email">long:</label>
                <b-input type="text" id="email" v-model="formData.email" />
              </div>
            </div>

            <!-- <b-row>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="Username:">
                  <template v-slot:label> Username <span class="text-danger"> *</span> </template>
                  <b-form-input id="quantity" v-model="fromIn.username" placeholder="Username" />
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4" label="Password:">
                  <template v-slot:label> Password <span class="text-danger"> *</span> </template>
                  <b-form-input id="quantity" v-model="fromIn.password" type="password" placeholder="Password" />
                </b-form-group>
              </b-row>
              <div class="row">
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4">
                  <template v-slot:label> ชื่อ<span class="text-danger"> *</span> </template>
                  <b-form-input id="detall" v-model="fromIn.firstname" placeholder="ชื่อ" />
                </b-form-group>
                <b-form-group class="col-md-6" label-cols="3" label-cols-lg="4">
                  <template v-slot:label> นามสกุล <span class="text-danger"> *</span> </template>
                  <b-form-input id="detall" v-model="fromIn.lastname" placeholder="นามสกุล" />
                </b-form-group>
              </div> -->
            <b-row>
              <!-- <b-form-group
                  class="col-md-6"
                  label-cols="3"
                  label-cols-lg="4"
                  label="ตำแหน่ง:"
                >
                  <template v-slot:label>
                    ตำแหน่ง <span class="text-danger"> *</span>
                  </template>
                  <b-form-input id="quantity" placeholder="ตำแหน่ง" />
                </b-form-group> -->
              <!-- <b-form-group
                  class="col-md-6"
                  label-cols="3"
                  label-cols-lg="4"
                  label="สถานะ:"
                >
                  <template v-slot:label>
                    สถานะ <span class="text-danger"> *</span>
                  </template>
                  <v-select v-model="status" label="title" :options="statuss" />
                </b-form-group> -->
            </b-row>
            <!-- <b-form-group label-cols="3" label-cols-lg="2" label="กลุ่มงาน:">
              <template v-slot:label> กลุ่มงาน <span class="text-danger"> *</span> </template>
              <v-select
                placeholder="เลือกกลุ่มงาน"
                v-model="workG"
                label="work_gname"
                :options="workGs"
                @input="work_select($event)"
              />
            </b-form-group>
            <b-form-group label-cols="3" label-cols-lg="2" label="ฝ่ายงาน:">
              <template v-slot:label> ฝ่ายงาน <span class="text-danger"> *</span> </template>
              <v-select placeholder="เลือกฝ่ายงาน" v-model="department" label="dep_name" :options="departments" />
            </b-form-group>
            <div>
              <b-form-group label-cols="3" label-cols-lg="2" label="หัวหน้ากลุ่มงาน:">
                <template v-slot:label> หัวหน้ากลุ่มงาน <span class="text-danger"> </span> </template>
                <b-form-checkbox
                  v-model="group_head_value"
                  name="check-button"
                  checked="true"
                  switch
                  inline
                  @change="group_head(group_head_value)"
                />
              </b-form-group>
            </div>
            <b-form-group label-cols="3" label-cols-lg="2" label="ฝ่ายงาน:">
              <template v-slot:label> Status <span class="text-danger"> *</span> </template>
              <v-select placeholder="เลือกฝ่ายงาน" v-model="status" label="title" :options="statuss" />
            </b-form-group> -->
            <!-- <div class="row justify-content-between">
                <b-form-grop class="col-3">
                  <label class="text-success">วัสดุ</label>
                  <b-form-checkbox value="รับเข้าวัสดุ">
                    รับเข้าวัสดุ
                  </b-form-checkbox>
                  <b-form-checkbox value="วัสดุคงคลัง">
                    วัสดุคงคลัง
                  </b-form-checkbox>
                  <b-form-checkbox value="เบิกวัสดุ"> เบิกวัสดุ </b-form-checkbox>
                  <b-form-checkbox value="ค้นหาวัสดุ">
                    ค้นหาวัสดุ
                  </b-form-checkbox>
                </b-form-grop>
                <b-form-grop class="col-3">
                  <label class="text-warning">ทะเบียนทรัพย์สิน</label>
                  <b-form-checkbox value="รับเข้าทะเบียนทรัพย์สิน">
                    รับเข้าทะเบียนทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="ทะเบียนทรัพย์สินคงคลัง">
                    ทะเบียนทรัพย์สินคงคลัง
                  </b-form-checkbox>
                  <b-form-checkbox value="เบิกทะเบียนทรัพย์สิน">
                    เบิกทะเบียนทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="ยืมทะเบียนทรัพย์สิน">
                    ยืมทะเบียนทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="คืนทะเบียนทรัพย์สิน">
                    คืนทะเบียนทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="ค้นหาทะเบียนทรัพย์สิน">
                    ค้นหาทะเบียนทรัพย์สิน
                  </b-form-checkbox>
                </b-form-grop>
                <b-form-grop class="col-3">
                  <label class="text-info">ตรวจสอบทะเบียนทรัพย์สิน</label>
                  <b-form-checkbox value="ประมวลผลค่าเสื่อมประจำปี">
                    ประมวลผลค่าเสื่อมประจำปี
                  </b-form-checkbox>
                  <b-form-checkbox value="ตรวจนับทรัพย์สิน">
                    ตรวจนับทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="ตรวจสอบประจำปี">
                    ตรวจสอบประจำปี
                  </b-form-checkbox>
                  <b-form-checkbox value="จำหน่ายทรัพย์สิน">
                    จำหน่ายทรัพย์สิน
                  </b-form-checkbox>
                </b-form-grop>
                <b-form-grop class="col-3">
                  <label class="text-danger">ตั้งค่าข้อมูล</label>
                  <b-form-checkbox value="รับเข้าวัสดุ">
                    กลุ่มวัสดุ
                  </b-form-checkbox>
                  <b-form-checkbox value="วัสดุคงคลัง">
                    กลุ่มทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="ทะเบียนคุมวัสดุ">
                    ทะเบียนคุมวัสดุ
                  </b-form-checkbox>
                  <b-form-checkbox value="ทะเบียนคุมทรัพย์สิน">
                    ทะเบียนคุมทรัพย์สิน
                  </b-form-checkbox>
                  <b-form-checkbox value="กลุ่มงาน"> กลุ่มงาน </b-form-checkbox>
                  <b-form-checkbox value="ผู้ใช้งาน"> ผู้ใช้งาน </b-form-checkbox>
                </b-form-grop>
              </div> -->
          </div>
        </b-form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { BFormInput, BRow, BFormGroup, BButton, BFormFile, BFormSelect } from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { saveExcel } from "@progress/kendo-vue-excel-export";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BFormSelect,
    BFormFile,
    BRow,
    BButton,
    vSelect,
    BFormInput,
    BFormGroup,
  },

  data() {
    return {
      API: `${API}`,
      show: false,
      fromIn: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
      },
      Previewimage: null,
      image: null,
      number: "",
      dir: false,
      status: { title: "ผู้ใช้ทั่วไป" },
      statuss: [
        { title: "แอดมิน" },
        { title: "ผู้ใช้ทั่วไป" },
        { title: "เจ้าหน้าที่พัสดุ" },
        { title: "ผู้ตรวจสอบ" },
        { title: "ลาออก" },
      ],
      workG: "",
      workGs: [],
      department: "",
      departments: [],
      columns: [
        {
          label: "วันที่สร้าง",
          field: "createdAt",
        },
        {
          label: "ชื่อ",
          field: "firstname",
        },
        {
          label: "นามสกุล",
          field: "lastname",
        },
        {
          label: "Username",
          field: "username",
        },
        //  {
        //   label: "Password",
        //   field: "password",
        // },
        {
          label: "status",
          field: "usertype",
        },
        {
          label: "กลุ่มงาน",
          field: "work_gid",
        },
        {
          label: "ฝ่ายงาน",
          field: "dep_id",
        },
        {
          label: "วันที่แก้ไข",
          field: "updatedAt",
        },

        {
          field: "ดูรายการ",
        },
      ],
      rows: [],
      total: "",
      pageLength: 100,
      currentPage: 1,
      searchTerm: "",
      group_head_value: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      formData: {
        name: "",
        email: "",
      },
    };
  },
  computed: {
    layout() {
      // console.log(this.$route.mete.permissions);
    },
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    this.work();
    //   this.get_data_table();
  },
  methods: {
    add_user() {
      this.group_head_value = false;
    },
    edit_user(value) {
      console.log(value);
      this.group_head_value = value.head;
    },
    group_head(value) {
      console.log(value);
    },
    async delete_user(value) {
      console.log(value);
      const { firstname, lastname, usertype } = value;
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `${firstname} ${lastname} ${usertype} `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const refresh_token = this.refresh_token();
          const url = `${API}delAccount`;
          const res = await axios.delete(url, {
            headers: {
              Authorization: refresh_token,
            },
            data: {
              username: value?.username,
            },
          });

          console.log(res.data.message);
          if (res.data.message.status === "Delete Account Complete.") {
            Swal.fire({
              icon: "success",
              title: "ลบข้อมูลสำเร็จ!",
              text: `รายการนี้ ถูกลบเเล้ว `,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "ลบข้อมูลไม่สำเร็จ!",
              text: `${res.data.message.status}`,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        }
        this.get_data_table();
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "ผู้ใช้งาน",
        columns: [
          {
            title: "ผู้ใช้งาน",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วันที่สร้าง",
                field: "createdAt",
              },
              {
                title: "ชื่อ",
                field: "firstname",
              },
              {
                title: "นามสกุล",
                field: "lastname",
              },
              {
                title: "กลุ่มงาน",
                field: "work_gid",
              },
              {
                title: "ฝ่ายงาน",
                field: "dep_id",
              },
              {
                title: "status",
                field: "usertype",
              },
              {
                title: "Username",
                field: "username",
              },
            ],
          },
        ],
      });
    },
    async up_user(value) {
      console.log(value.row);
      console.log(this.group_head_value);
      const { access_token } = await this.access_token();

      const fullname = `${value.row.firstname} ${value.row.lastname}`;

      // console.log(this.status.title);

      const url_a = `${API}setUserPermission`;
      const data_a = {
        username: value.row.username,
        usertype: value.row.usertype.title || value.row.usertype,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_a = await axios.put(url_a, data_a, head);

      const url_b = `${API}user/${value.row.id}`;
      const data_b = {
        username: value.row.username,
        work_gid: value.row.work_gid.work_gname,
        dep_id: value.row.dep_id.dep_name,
        firstname: value.row.firstname,
        lastname: value.row.lastname,
      };
      const res_b = await axios.put(url_b, data_b, head);
      console.log(res_a);
      console.log(res_b);
      const api_group_head = `${API}headWorkgroup`;
      const data = {
        head: `${this.group_head_value}`,
        fullname: fullname,
      };
      const group_header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_aaa = await axios.put(api_group_head, data, group_header);
      console.log(res_aaa);
      if (res_aaa.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_data_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: res_aaa.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}user?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);

      const res_map = res.data.message.data.map((res_x) => {
        return {
          ...res_x,
          image: res_x.image ? JSON.parse(res_x.image) : [],
          createdAt: `${parseInt(res_x.createdAt.split("T")[0].split("-")[2])} ${
            this.month[parseInt(res_x.createdAt.split("T")[0].split("-")[1])]
          } ${Number(res_x.createdAt.split("T")[0].split("-")[0]) + 543}`,
          updatedAt: `${parseInt(res_x.updatedAt.split("T")[0].split("-")[2])} ${
            this.month[parseInt(res_x.updatedAt.split("T")[0].split("-")[1])]
          } ${Number(res_x.updatedAt.split("T")[0].split("-")[0]) + 543}`,
        };
      });

      // console.log(res_map);
      //  this.rows = res_map;
      this.rows = res_map;
      this.total = res.data.message.total;
      this.show = false;
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_data_table();
    },

    async work() {
      const { access_token } = await this.access_token();
      const workgroups = `${API}workgroups?_page=1&_limit=100&_sort=1`;
      const header_workgroups = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_workgroups = await axios.get(workgroups, header_workgroups);
      this.workGs = res_workgroups.data.message.data;
    },
    async work_select(event) {
      const { access_token } = await this.access_token();
      const m_gid = event.work_gid;
      // console.log(m_gid);
      const url = `${API}departments?work_gid=${m_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);

      this.departments = res.data.message.data;
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.Previewimage = URL.createObjectURL(file);
      this.image = e;
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    refresh_token() {
      return localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData"));
    },
    // basic
    async showAlert() {
      this.$emit("submit", this.formData);
      //   const data = {
      //     username: this.fromIn.username,
      //     password: this.fromIn.password,
      //     work_gid: this.workG.work_gid,
      //     dep_id: this.department.dep_id,
      //     firstname: this.fromIn.firstname,
      //     lastname: this.fromIn.lastname,
      //     serial: "WACR-ESEA-RCHC-OLTD",
      //   };
      //   const { access_token } = await this.access_token();
      //   const url = `${API}regis`;
      //   const head = {
      //     headers: {
      //       Authorization: await access_token,
      //     },
      //   };
      //   const res = await axios.post(url, data, head);
      //   console.log(res);
      if (res.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_data_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
